import { css } from '@emotion/react'

import { Text2Styles } from '@/styles/typography'
import { mediaUp } from '@/styles/media'
import { colors, weights, transitions } from '@/styles/theme'

export const styles = {
  labels: css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 8px;

    ${mediaUp('md')} {
      flex-wrap: nowrap;
    }

    ${mediaUp('lg')} {
      margin-bottom: 30px;
    }
  `,
  hover: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: ${colors.twilightBlue};
    border-radius: 9px;
    padding: 4px 8px;
    margin-bottom: 4px;
    color: ${colors.white};
    font-weight: ${weights.semibold};
    font-size: 10px;
    line-height: 1;
    opacity: 0;
    transition: opacity ${transitions.base};
    pointer-events: none;
  `,
  labelsShort: (positionAbsolute: boolean, noMargin: boolean) => css`
    position: ${positionAbsolute ? 'absolute' : 'relative'};
    top: 0;
    right: 0;
    margin: ${noMargin ? '0px' : '14px'};
    justify-content: end;
    max-width: calc(100% - 85px - 32px);

    ${mediaUp('md')} {
      flex-wrap: wrap;
    }

    ${mediaUp('lg')} {
      margin: ${noMargin ? '0px' : '30px'};
      max-width: calc(100% - 150px - 90px);
    }

    html[lang^='ar'] & {
      right: unset;
      left: 0;
    }
  `,
  resultLabelsShort: (positionAbsolute: boolean, noMargin: boolean) => css`
    position: relative;
    margin: ${noMargin ? '0px' : '14px'};
    justify-content: start;
    max-width: 100%;
    ${mediaUp('md')} {
      flex-wrap: wrap;
      position: ${positionAbsolute ? 'absolute' : 'relative'};
      justify-content: end;
      max-width: calc(100% - 85px - 32px);
      top: 0;
      right: 0;
    }
    ${mediaUp('lg')} {
      margin: ${noMargin ? '0px' : '30px'};
      max-width: calc(100% - 150px - 90px);
    }

    html[lang^='ar'] & {
      right: unset;
      left: 0;
    }
  `,
  label: css`
    ${Text2Styles};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    height: 18px;
    border-radius: 6px;
    margin-top: 0;
    margin-inline-start: 0;
    padding: 0 6px;
    white-space: nowrap;
    margin-inline-end: 8px;

    &:hover span {
      opacity: 1;
    }

    ${mediaUp('lg')} {
      height: 32px;
      border-radius: 10px;
      margin-bottom: 0;
      padding: 0 12px;
      display: flex;
      gap: 8px;
      margin-inline-end: 16px;
    }

    svg {
      width: 8px;
      height: 8px;

      ${mediaUp('lg')} {
        width: 16px;
        height: 16px;
      }
    }
  `,
  labelShort: css`
    height: 21px;
    width: 21px;
    border-radius: 50%;
    margin-top: 0;
    margin-inline-end: 0;
    padding: 0;
    margin-right: 4px;

    ${mediaUp('lg')} {
      border-radius: 50%;
      padding: 0;
      height: 34px;
      width: 34px;
      margin-right: 8px;
    }

    svg {
      width: 11px;
      height: 11px;
      margin: 0;

      ${mediaUp('lg')} {
        width: 18px;
        height: 18px;
      }
    }
  `,
  labelDark: css`
    background-color: ${colors.twilightBlue};

    svg {
      fill: ${colors.white};
    }
  `,
  labelLight: css`
    background-color: ${colors.blueLight};
    color: ${colors.twilightBlue};
  `,
  labelRed: css`
    background-color: ${colors.pinkRed};
  `,
  labelBlue: css`
    background-color: ${colors.darkSkyBlue};
  `,
  labelGreen: css`
    background-color: ${colors.greenishCyan};
    color: ${colors.twilightBlue};
  `
}
