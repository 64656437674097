import { Parking } from '@/service'

export const getParkingAddress = (
  parking: Parking,
  t: (key: string) => string
) => {
  const translationCity: string | undefined =
    parking?.translationKeys?.parkingLocationCity
  const translationStreet: string | undefined =
    parking?.translationKeys?.parkingLocationStreet

  if (translationCity && translationStreet) {
    const street = t(translationStreet)
    const city = t(translationCity)
    return `${street}, ${city}`
  }

  return parking.address
}
