import { useTranslation } from 'next-i18next'

import { isMobile } from 'react-device-detect'

import { Parking } from '@/service'

import CalendarIcon from '@/assets/hint-reservation.svg?inline'
import ClockIcon from '@/assets/hint-subscription.svg?inline'
import AutopayIcon from '@/assets/autopay.svg?inline'
import ChargingIcon from '@/assets/charging.svg?inline'
import NaviPayIcon from '@/assets/navipay-mini.svg?inline'
import PlaneIcon from '@/assets/plane-icon.svg?inline'
import ScooterIcon from '@/assets/scooter-icon.svg?inline'
import ValetIcon from '@/assets/valet-icon.svg?inline'
import CarWashIcon from '@/assets/wash-icon.svg?inline'

import { styles } from './ParkingTags.styled'

type ParkingTagsProps = {
  parking: Parking
  short?: boolean
  positionAbsolute?: boolean
  noMargin?: boolean
  isResultLabels?: boolean
}

const ParkingTags: React.FC<ParkingTagsProps> = props => {
  const { t } = useTranslation('common')
  const {
    parking,
    short = false,
    positionAbsolute = true,
    noMargin = false,
    isResultLabels = false
  } = props

  const tags = {
    AUTOPAY: (
      <li css={[styles.label, short && styles.labelShort, styles.labelRed]}>
        <AutopayIcon />
        <span css={[short && styles.hover]}>{t('tags.autopay')}</span>
      </li>
    ),
    NAVIPAY: (
      <li css={[styles.label, short && styles.labelShort, styles.labelLight]}>
        <NaviPayIcon />
        <span css={[short && styles.hover]}>{t('tags.navipay')}</span>
      </li>
    ),
    EV_CHARGERS: (
      <li css={[styles.label, short && styles.labelShort, styles.labelBlue]}>
        <ChargingIcon />
        <span css={[short && styles.hover]}>{t('tags.charging')}</span>
      </li>
    ),
    PARK_AND_FLY: (
      <li css={[styles.label, short && styles.labelShort, styles.labelGreen]}>
        <PlaneIcon />
        <span css={[short && styles.hover]}>{t('tags.parkAndFly')}</span>
      </li>
    ),
    SCOOTERS: (
      <li css={[styles.label, short && styles.labelShort, styles.labelBlue]}>
        <ScooterIcon />
        <span css={[short && styles.hover]}>{t('tags.scooters')}</span>
      </li>
    ),
    VALET: (
      <li css={[styles.label, short && styles.labelShort, styles.labelBlue]}>
        <ValetIcon />
        <span css={[short && styles.hover]}>{t('tags.valet')}</span>
      </li>
    ),
    CAR_WASH: (
      <li css={[styles.label, short && styles.labelShort, styles.labelGreen]}>
        <CarWashIcon />
        <span css={[short && styles.hover]}>{t('tags.carWash')}</span>
      </li>
    )
  }

  return (
    <ul
      css={[
        styles.labels,
        short &&
          (isResultLabels
            ? styles.resultLabelsShort(positionAbsolute, noMargin)
            : styles.labelsShort(positionAbsolute, noMargin))
      ]}
    >
      {parking.subscriptionAvailable && (
        <li css={[styles.label, short && styles.labelShort, styles.labelDark]}>
          <CalendarIcon />
          <span css={[short && styles.hover]}>{t('tags.subscriptions')}</span>
        </li>
      )}
      {parking.reservationAvailable && (
        <li css={[styles.label, short && styles.labelShort, styles.labelDark]}>
          <ClockIcon />
          <span css={[short && styles.hover]}>{t('tags.reservations')}</span>
        </li>
      )}
      {parking.infoTags &&
        parking.infoTags?.map((item, index) => (
          <div key={index}>{tags[item]}</div>
        ))}
    </ul>
  )
}

export default ParkingTags
